<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" href="https://www.umohe.com" target="_blank">
              <v-icon size="16" color="primary">fa-home</v-icon>
            </v-btn>
          </template>
          <span>Go home</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body dash">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat dark color="rgb(34, 142, 255)" min-height="240">
            <v-overlay absolute :value="overUser"></v-overlay>
            <v-toolbar flat color="rgb(34, 142, 255)" dark>
              <v-toolbar-title style="color: white">
                My Appointment
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon :to="{ name: 'patient.appointments' }">
                <v-icon large>mdi-hospital</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding-bottom: 16px">
              <v-card flat color="rgb(34, 142, 255)" style="height: 170px">
                <v-card-text style="padding: 0; margin: 0">
                  <v-list
                    dense
                    style="background-color: transparent; padding: 0; margin: 0"
                  >
                    <v-list-item-group
                      v-for="(item, i) in appointmentlist"
                      :key="item.id"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.subscriberName"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.schedule_date | myDate }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>
                            {{ item.time_start | myDateTiming }} to
                            {{ item.time_end | myDateTiming }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider
                        v-if="i < appointmentlist.length - 1"
                      ></v-divider>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card flat>
            <v-overlay absolute :value="overTreat"></v-overlay>
            <v-sheet
              color="#f0f8fe"
              elevation="8"
              style="padding: 10px"
              rounded
              height="261"
            >
              <v-toolbar flat color="#f0f8fe">
                <v-toolbar-title>
                  <v-btn fab large depressed>
                    <v-icon color="indigo" size="64"> mdi-heart-pulse</v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="chartitle">
                  Health Monitor
                  <span class="sub">Last 7 Appointments</span>
                </div>
              </v-toolbar>

              <apexcharts
                width="99%"
                height="180"
                :options="chartOptions"
                :series="series"
              ></apexcharts>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="body dash">
      <v-card flat>
        <v-overlay absolute :value="overEarn"></v-overlay>
        <v-toolbar flat>
          <v-toolbar-title class="earn_title">
            health Advice
            <div class="sub">From Umohe Health Micro blog</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn> -->
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-iterator :items="blogList" item-key="id" :items-per-page="2">
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.name"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <div class="hadvice">
                    <ul>
                      <li>
                        <div class="header">
                          <div>{{ item.title }}</div>
                          <div>
                            {{ item.name }} {{ item.first_name }}
                            {{ item.mid_name }}
                            {{ item.last_name }}
                          </div>
                        </div>
                        <div class="text">
                          <div v-html="truncateString(item.details, 320)"></div>
                        </div>
                        <div class="footer">
                          <div>Published Date: {{ item.created | myDate }}</div>
                          <div>
                            <v-btn
                              text
                              x-small
                              color="success"
                              :to="{
                                name: 'patient.blogs',
                                params: { id: item.id },
                              }"
                            >
                              details
                            </v-btn>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import VueApexCharts from "vue-apexcharts";
import Restful from "@/services/RestFul";
import utils from "../../mixins/utils";

export default {
  components: { apexcharts: VueApexCharts },
  mixins: [utils, WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
      ],
      myHealthData: [
        {
          date: "July 17",
          blood: 122,
          temperature: 34,
          weight: 80,
        },
        {
          date: "July 18",
          blood: 102,
          temperature: 35,
          weight: 81,
        },
        {
          date: "July 19",
          blood: 85,
          temperature: 43,
          weight: 82,
        },
        {
          date: "July 20",
          blood: 70,
          temperature: 36,
          weight: 78,
        },
      ],
      knowledge: 33,
      overUser: false,
      overAppoint: false,
      overTreat: false,
      overEarn: false,
      overClient: false,
      appointmentlist: [
        {
          id: 1,
          text: "Kwesi Lennox",
          appoint: "2024-01-01",
          start: "12:00pm",
          ends: "02:00pm",
        },
        {
          id: 2,
          text: "Esi Benneh",
          appoint: "2024-01-01",
          start: "12:00pm",
          ends: "02:00pm",
        },
        {
          id: 3,
          text: "Awo Mannaseh",
          appoint: "2024-01-01",
          start: "12:00pm",
          ends: "02:00pm",
        },
      ],
      blogList: [],
      myName: null,
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.myName = localStorage.getItem("user.name");
    this.list();
    this.appointment();
    this.myHealth();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.blogs
        .active()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.blogList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    appointment() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
        .appointment()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.appointmentlist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    myHealth() {
      let date = this.myHealthData.map((w) => w.date);
      let blood = this.myHealthData.map((w) => w.blood);
      let temperature = this.myHealthData.map((w) => w.temperature);
      let weight = this.myHealthData.map((w) => w.weight);

      console.log("date:", date);
      console.log("blood:", blood);
      console.log("temperature:", temperature);
      console.log("weight:", weight);

      (this.series = [
        {
          name: "Blood Pressure",
          data: blood,
        },
        {
          name: "Temperature",
          data: temperature,
        },
        {
          name: "Weight",
          data: weight,
        },
      ]),
        (this.chartOptions = {
          chart: {
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 5,
              left: 2,
              blur: 6,
              opacity: 0.1,
            },
            toolbar: {
              show: false,
            },
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            categories: date,
          },
          fill: {
            opacity: 0.7,
            type: "area",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100],
            },
          },
          responsive: [
            {
              breakpoint: 800,
              options: {
                plotOptions: {
                  line: {
                    horizontal: false,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          stroke: {
            curve: "smooth",
          },
        });
    },
  },
};
</script>
